@import '../../../../vars';

.app-error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  padding: 0 $spacing-large;
}

.app-error-image {
  flex-grow: 1;
  max-width: 850px;
}

.app-error-header-container {
  margin-left: 80px;
}

.app-error-header {
  width: 550px;
  font-size: 3.4rem;
  line-height: 1.5;
}

.app-error-page-back-button {
  margin-top: $spacing-extra-large;
}

@media screen and (max-width: $laptop) {
  .app-error-header {
    max-width: 450px;
    font-size: 2.8rem;
  }

  .app-error-page-back-button {
    margin-top: $spacing-large;
  }
}

@media screen and (max-width: $mobile) {
  .app-error-image {
    flex-grow: 0;
    width: 484px;
  }

  .app-error-page {
    flex-direction: column;
  }

  .app-error-header-container {
    margin-left: 0;
    text-align: center;
  }
}
