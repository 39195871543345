@import './projects/loki-desktop/src/vars';

@import 'icons';

.icon {
  width: 24px; height: 24px;
  display: inline-block; vertical-align: top; flex-shrink: 0;
  background-repeat: no-repeat; background-position: center;
}

svg-icon-sprite, .svg-icon {
  width: 24px; height: 24px;
  color: $black;

  &-interactive {
    color: $black-lighten;
    cursor: pointer;

    &:hover {color: $black;}
  }
  &-loader {
    pointer-events: none;
    animation: spin 1s infinite linear;

    @keyframes spin {
      to {transform: rotate(360deg);}
    }
  }
}
