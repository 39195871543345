/* colors */
$white: #ffffff;
$gray-bleach: #fcfcfd;
$gray-light: #f9fafc;
$gray: #f2f3f8;
$gray-dark: #eff0f4;
$gray-darker: #ebedf2;
$gray-dove: #dbddea;
$icon-color: #868aa8;

$orange-light: #ffba76;
$orange: #ff8d1d;
$orange-dark: #f86700;
$green-light: #d0dbb4;
$green: #adce00;
$green-dark: #8ca700;
$red: #f4516c;
$red-light: #fdf4f5;

$black: #3d3a55;
$black-lighten: #787a81;

$text: #575962;
$text-dark: #3d3b56;

/* fonts */
$font: 'Poppins', sans-serif;

/* font sizes */
$default: 10px;

$font-size-h1: 2.1rem;
$font-size-h2: 1.8rem;
$font-size-h3: 1.6rem;
$font-size-h4: 1.4rem;
$font-size-p: 1.4rem;
$font-size-placeholder: 1.4rem;
$font-size-tiny: 1.2rem;

$line-height-h1: 2.9rem;
$line-height-h2: 2.5rem;
$line-height-h3: 2.3rem;
$line-height-h4: 2rem;
$line-height-p: 2rem;
$line-height-placeholder: 2rem;
$line-height-tiny: 1.7rem;

$font-size-h1-responsive: 1.6rem;
$font-size-h2-responsive: 1.4rem;
$font-size-h3-responsive: 1.4rem;
$font-size-h4-responsive: 1.2rem;
$font-size-p-responsive: 1.2rem;
$font-size-placeholder-responsive: 1.2rem;
$font-size-tiny-responsive: 1rem;

$line-height-h1-responsive: 2.3rem;
$line-height-h2-responsive: 2rem;
$line-height-h3-responsive: 2rem;
$line-height-h4-responsive: 1.7rem;
$line-height-p-responsive: 1.7rem;
$line-height-placeholder-responsive: 1.7rem;
$line-height-tiny-responsive: 1.5rem;

$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;
$font-weight-extra-light: 200;

/* spacing */
$spacing-tiny: 4px;
$spacing-extra-small: 8px;
$spacing-slightly-small: 10px;
$spacing-small: 12px;
$spacing-regular: 20px;
$spacing-large: 30px;
$spacing-extra-large: 50px;

$spacing-large-responsive: 15px;
$spacing-extra-large-responsive: 30px;

/* corners */
$rounded: 4px;
$rounded-strong: 12px;

/* misc */
$border: 1px solid $gray-dove;
$border-alert: 1px solid $red;
$border-disabled: 1px solid $gray-dark;
$shadow-color: rgba(82, 63, 105, 0.16);
$shadow-standard: 0 2px 4px 0 $shadow-color;
$shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.08);

/* responsive resolutions */
$fullHD: 1920px;
$laptop: 1440px;
$tablet: 1280px - 1px;
$mobile: 988px - 1px;
$fluid: 768px;
