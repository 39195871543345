@import './projects/loki-desktop/src/vars';

.toggle {
  position: relative;
  display: flex;
  align-self: flex-start;
  padding-left: $spacing-small + 50px;
  text-align: start;
  cursor: pointer;

  input {opacity: 0; border: 0; width: 0; height: 0;}
  input:checked + &-slider:before {background: $green;}
  input:checked + &-slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }
  input:disabled ~ &-slider {
    background: $gray-light;
    border: $border-disabled;
    transition: all .2s;
  }
  input:checked:disabled + &-slider:before {background: $gray-dark;}
  input:disabled ~ span.p {
    color: rgba($text-dark, .2);
    transition: all .2s;
  }

  span.p {
    line-height: 26px;
    transition: all .4s;
  }
}

.toggle-slider {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(-50%);
  width: 50px; height: 26px;
  background: $white;
  border: $border; border-radius: $rounded-strong;
  transition: all .4s;

  &:before {
    content: '';
    position: absolute;
    left: 2px; bottom: 1px;
    height: 22px; width: 22px;
    background: $gray;
    border-radius: 50%;
    transition: all .4s;
  }
}
