// @import './projects/loki-desktop/src/vars';

@import './projects/loki-desktop/src/vars';


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap');

@import '@angular/cdk/overlay-prebuilt.css';
@import 'app/shared/components/typography/typography';
@import 'app/shared/components/button/button';
@import 'app/shared/components/input/input';
@import 'app/shared/components/icon/icon';
@import 'app/shared/components/badge/badge';
@import 'app/shared/components/checkbox/checkbox';
@import 'app/shared/components/toggle/toggle';
@import 'app/shared/components/option/option';
@import 'app/shared/components/error-page/error-page';

app-root {box-sizing: border-box;}

* {
  margin: 0; padding: 0;
  box-sizing: inherit;
  scroll-behavior: smooth;
  outline: 0 none;
}

html {font-size: $default; font-family: $font;}
html, body {height: 100%;}

.status {
  display: inline-block;
  height: 10px; width: 10px;
  background: $gray-dove;
  border-radius: 50%;

  &-success {background: $green;}
  &-warn {background: $red;}
}

.divider {
  display: block;
  border-bottom: $border;

  &-vertical {
    display: inline-block;
    height: 100%;
    vertical-align: bottom;
    border-right: $border; border-bottom: none;
  }
}

.form {
  &-control {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 24px;

    // todo: extract rules to elem class '&-label'
    & > .p {
      display: block;
      white-space: nowrap;
      &:not(:empty) {margin-bottom: $spacing-small;}
    }

    .warn {
      position: absolute; display: block;
      margin-top: 2px;
      font-size: $default;
    }

    &-initial {margin: 0;}
  }
}

.dialog {
  .mat-dialog-container {
    width: 100vw; max-width: 1058px;
    padding: 0; margin: $spacing-extra-large 0;
    border-radius: 0;
  }
  &-small {
    .mat-dialog-container {width: auto; min-width: 540px;}
  }
  .portlet {
    display: flex; flex-direction: column;
    max-height: calc(100vh - 100px);
  }
  .portlet-section, div.portlet-section {flex: 0 0 auto;}
  .portlet-content {
    flex: 0 1 auto;
  }
  .portlet-actions {
    padding-top: $spacing-regular;
    button {min-width: 212px;}
    & > :first-child {margin-right: auto;}
    & > :not(:first-child) {margin-left: $spacing-large;}
  }
}

/** @deprecated Use `app-portlet-actions` directive instead */
.actions {
  display: flex; align-items: center;
  padding-top: $spacing-extra-large;

  button {min-width: 212px;}
  & > :first-child {margin-right: auto;}
  & > :not(:first-child) {margin-left: $spacing-large;}
  &-left {
    & > :not(:first-child) {margin-left: 0;}
    & > :last-child {margin-left: auto;}
    & > :not(:last-child) {margin-right: $spacing-large;}
  }
}
