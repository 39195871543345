@import './projects/loki-desktop/src/vars';

.checkbox, .radio {
  position: relative;
  display: block;
  padding-left: $spacing-small + 20px;
  text-align: start;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input, &-checkmark {position: absolute;}
  input {opacity: 0; border: 0; height: 0; width: 0;}
  input:checked ~ &-checkmark {
    background: $green;
    border: transparent;
    &:after {display: block;}
  }
  input:disabled ~ &-checkmark {
    background: $gray-light;
    border: $border-disabled;
    transition: all .2s;
  }
  input:checked:disabled ~ &-checkmark {border: none;}
  // todo: move to form control
  input:disabled ~ span.p {
    color: rgba($text-dark, .2);
    transition: all .2s;
  }

  &-checkmark {
    top: 0; left: 0;
    height: 18px; width: 18px;
    border: $border; border-radius: $rounded;
    background: $white;
    transition: all .4s;

    &:after {content: ''; position: absolute; display: none;}
  }
  &-checkmark:after {
    left: 6px; top: 2px;
    width: 5px; height: 10px;
    border: solid $white;
    border-width: 0 1px 1px 0;

    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  span.p {
    line-height: 18px;
    transition: all .4s;
  }
}

.radio .radio-checkmark {border-radius: 50%;}

@media screen and (max-width: $mobile) {
  .checkbox, .radio {
    padding-left: $spacing-small + 24px;

    &-checkmark {height: 24px; width: 24px;}
    &-checkmark:after {
      left: 8px; top: 3px;
      width: 7px; height: 12px;
    }

    span.p {line-height: 24px;}
  }
}
