@import './projects/loki-desktop/src/vars';

.badge {
  display: inline-block;
  font-size: 11px; line-height: 22px; color: $text-dark;
  height: 22px;
  padding: 0 $spacing-regular;
  border-radius: $rounded-strong;
  text-align: center;
}

.badge-gray {background: $gray;}
.badge-orange, .badge-green {color: $white;}
.badge-orange {background: $orange;}
.badge-green {background: $green;}
