/* icon variants */
.icon {
  &-temp {background-image: url(/assets/icons/temp.svg);}
  &-contract {background-image: url(/assets/icons/contract.svg);}
  &-contract-active {background-image: url(/assets/icons/contract-active.svg);}
  &-help {background-image: url(/assets/icons/help.svg);}
  &-help-active {background-image: url(/assets/icons/help-active.svg);}
  &-marketing {background-image: url(/assets/icons/marketing.svg);}
  &-marketing-active {background-image: url(/assets/icons/marketing-active.svg);}
  &-order {background-image: url(/assets/icons/order.svg);}
  &-order-active {background-image: url(/assets/icons/order.svg);}
  &-shop {background-image: url(/assets/icons/shop.svg);}
  &-shop-active {background-image: url(/assets/icons/shop-active.svg);}
  &-print {background-image: url(/assets/icons/printer.svg);}
  &-print-active {background-image: url(/assets/icons/print-active.svg);}
  &-dashboard {background-image: url(/assets/icons/dashboard.svg);}
  &-dashboard-active {background-image: url(/assets/icons/dashboard-active.svg);}
  &-home {background-image: url(/assets/icons/home.svg);}
  &-dots {background-image: url(/assets/icons/dots.svg);}
  &-more {background-image: url(/assets/icons/more_2.svg);}
  &-more-darken {background-image: url(/assets/icons/more.svg);}
  &-double-chevron {background-image: url(/assets/icons/double_chevron.svg);}
  &-chevron-left {background-image: url(/assets/icons/chevron.svg);}
  &-chevron-right {background-image: url(/assets/icons/chevron_2.svg);}
  &-chevron-down {background-image: url(/assets/icons/chevron_3.svg);}
  &-search {background-image: url(/assets/icons/search.svg);}
  &-close {background-image: url(/assets/icons/close.svg);}
  &-chevron-down-tiny {background-image: url(/assets/icons/table_chevron_down_2.svg);}
  &-chevron-down-tiny-darken {background-image: url(/assets/icons/table_chevron_down.svg);}
  &-chevron-up-tiny {background-image: url(/assets/icons/table_chevron_up_2.svg);}
  &-chevron-up-tiny-darken {background-image: url(/assets/icons/table_chevron_up.svg);}
  &-edit {background-image: url(/assets/icons/edit.svg);}
  &-edit-tiny {background-image: url(/assets/icons/edit-tiny.svg);}
  &-edit-tiny-darken {background-image: url(/assets/icons/edit-tiny-darken.svg);}
  &-check {background-image: url(/assets/icons/check.svg);}
  &-key {background-image: url(/assets/icons/key.svg);}
  &-lock {background-image: url(/assets/icons/lock.svg);}
  &-unlock {background-image: url(/assets/icons/unlock.svg);}
  &-trash {background-image: url(/assets/icons/trash.svg);}
  &-en {background-image: url(/assets/icons/united-states-of-america.svg);}
  &-de {background-image: url(/assets/icons/germany.svg);}
  &-pl {background-image: url(/assets/icons/poland.svg);}
  &-ru {background-image: url(/assets/icons/russia.svg);}
  &-fb-white {background-image: url(/assets/icons/fb_white.svg);}
  &-fb {background-image: url(/assets/icons/fb.svg);}
  &-inst-white {background-image: url(/assets/icons/inst_white.svg);}
  &-inst {background-image: url(/assets/icons/inst.svg);}
  &-yt {background-image: url(/assets/icons/youtube.svg);}
  &-gear {background-image: url(/assets/icons/gear.svg);}
  &-gear-active {background-image: url(/assets/icons/gear-active.svg);}
  &-enter {background-image: url(/assets/icons/enter.svg);}
  &-enter-darken {background-image: url(/assets/icons/enter-darken.svg);}
  &-branch {background-image: url(/assets/icons/branch.svg);}
  &-branch-darken {background-image: url(/assets/icons/branch-darken.svg);}
  &-select-arrows {background-image: url(/assets/icons/select_arrows.svg);}
  &-stat {background-image: url(/assets/icons/stat.svg);}
  &-stat-darken {background-image: url(/assets/icons/stat-darken.svg);}
  &-filter {background-image: url(/assets/icons/filter.svg);}
  &-filter-hover {background-image: url(/assets/icons/filter-hover.svg);}
  &-filter-active {background-image: url(/assets/icons/filter-active.svg);}
}
