@import './projects/loki-desktop/src/vars';

h1, .h1 {font-size: $font-size-h1; line-height: $line-height-h1; color: $text-dark; font-weight: $font-weight-medium;}
h2, .h2 {font-size: $font-size-h2; line-height: $line-height-h2; color: $text-dark; font-weight: $font-weight-medium;}
h3, .h3 {font-size: $font-size-h3; line-height: $line-height-h3; color: $text-dark; font-weight: $font-weight-medium;}
h4, .h4 {font-size: $font-size-h4; line-height: $line-height-h4; color: $text-dark; font-weight: $font-weight-medium;}
p, .p {font-size: $font-size-p; line-height: $line-height-p; color: $text;}
.tiny {font-size: $font-size-tiny; line-height: $line-height-tiny; color: $text; font-weight: $font-weight-light; opacity: .8;}
.placeholder {font-size: $font-size-placeholder; line-height: $line-height-placeholder; color: $text; font-weight: $font-weight-extra-light; opacity: .8;}

a, .a {
  display: inline-block;
  font-size: $font-size-p; line-height: $line-height-p; color: $text;
  text-decoration: none;
  cursor: pointer;

  &:hover {color: $orange;}
}

s, .strike {text-decoration: line-through;}
b, .bold {font-weight: $font-weight-medium;}

.highlight {color: $orange;}
.pending {color: $black-lighten;}
.success {color: $green;}
.warn {color: $red;}

.nowrap {white-space: nowrap;}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Chrome, Edge, Opera and Firefox */
}

@media screen and (max-width: $laptop) {
  h1, .h1 {font-size: $font-size-h1-responsive; line-height: $line-height-h1-responsive;}
  h2, .h2 {font-size: $font-size-h2-responsive; line-height: $line-height-h2-responsive;}
  h3, .h3 {font-size: $font-size-h3-responsive; line-height: $line-height-h3-responsive;}
  h4, .h4 {font-size: $font-size-h4-responsive; line-height: $line-height-h4-responsive;}
  p, .p {font-size: $font-size-p-responsive; line-height: $line-height-p-responsive;}
  .tiny {font-size: $font-size-tiny-responsive; line-height: $line-height-tiny-responsive;}
  .placeholder {font-size: $font-size-placeholder-responsive; line-height: $line-height-placeholder-responsive;}

  a, .a, .link {font-size: $font-size-p-responsive; line-height: $line-height-p-responsive;}
}
